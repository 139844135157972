import { postWrapper } from "./utils/axioUtils";
import { formReset, customFormReset } from "./utils/utils";

export const getAccessKeys = (context) => {
  postWrapper(
    "https://caci.blacklitnetwork.org/api/accessKeys",
    {
      type: "accessKeys",
    },
    (data = []) => {
      context.setState({
        accessSelect: data,
      });
    },
  );
};

export const getTopics = (context) => {
  postWrapper(
    "https://caci.blacklitnetwork.org/api/topics",
    {
      type: "topics",
    },
    (data = []) => {
      context.setState({
        topicSelect: data,
      });
    },
  );
};

export const getTiers = () => {
  postWrapper("https://caci.blacklitnetwork.org/api/tiers", {}, (data = []) => {
    window.regionsData = data;
  });
};

const getCollectionData = (context, data = {}) => {
  const formData = new FormData(context.form);
  const photos = document.getElementById("collection-photos").files;
  for (let i = 0; i < photos.length; i += 1) {
    formData.append("photos", photos[i]);
  }
  formData.append("formData", JSON.stringify(data));
  return formData;
};

export const formSubmission = (context, event, { data }) => {
  const {
    formName,
    router: { navigate },
  } = context.props;
  postWrapper(
    `https://caci.blacklitnetwork.org/api/${formName}`,
    getCollectionData(context, data),
    () => {
      if (formName === "insert-collection") {
        context.notyf.success(
          "The collection has been successfully sent for Approval!",
        );
        document.getElementById("row-of-product-photos").textContent = "";
        document.getElementById("insert-image-preview").textContent = "";
        document.getElementById("collection-photos").value = "";
        if (event.target.id === "submit-finish") {
          formReset(context);
        } else {
          customFormReset(context);
          document.getElementById("four").scrollIntoView(true);
        }
        return;
      }
      if (formName === "approve-collection") {
        context.notyf.success("The collection has been Approved!");
      } else {
        context.notyf.success("The collection has been Updated!");
      }
      setTimeout(() => {
        navigate("/caci/admin-list");
      }, 2000);
    },
    () => {
      context.setState({
        ...context.state,
        preventSubmit: false,
      });
    },
  );
};
