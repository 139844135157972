import {
    updateCountryStateCityData,
    fileInputChangeListener,
  } from "./utils/utils";
  import { closeModal } from "./imageUploadUtils";
  
  const runOnMount = (context) => {
    updateCountryStateCityData(context, "countries", "", (result) => {
      const options = [];
      JSON.parse(result).forEach((obj) => {
        options.push({
          label: obj.name,
          value: obj.iso2 || obj.name,
        });
      });
      context.setState({
        locationSelect: {
          ...context.state.locationSelect,
          countries: options,
        },
      });
    });
    context.form.addEventListener(
      "submit",
      (event) => {
        event.preventDefault();
      },
      false,
    );
    context.modal = document.getElementById("myModal");
    window.onclick = function windowClickHandler(event) {
      if (event.target === context.modal) {
        closeModal(context);
      }
    };
  
    if (window.File && window.FileList && window.FileReader) {
      const inputFile = document.getElementById("collection-photos");
      inputFile.addEventListener("change", fileInputChangeListener);
    } else {
      // eslint-disable-next-line no-console
      console.log("Your browser does not support File API");
    }
  };
  
  export default runOnMount;
  