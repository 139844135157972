import React, { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const TagButton = ({ children, onClick }) => (
  <button
    onClick={onClick}
    style={{
      display: 'flex',
      padding: '6px 12px',
      alignItems: 'center',
      gap: '4px',
      borderRadius: '8px',
      border: '0.25px solid var(--Gray-Black, #00000090)',
      background: 'transparent',
      cursor: 'pointer',
      color: '#00000090'
    }}
  >
    {children}
  </button>
);

const RelatedArchivesForPodcast = ({ podcast }) => {
  const [relatedArchives, setRelatedArchives] = useState([]);
  const [loading, setLoading] = useState(false);
  const containerRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (!podcast) return;

    const fetchRelatedArchives = async () => {
      setLoading(true);
      try {
        // Construct the search terms from the podcast's name, host, and notes
        const searchTerms = [
          podcast.name,
          podcast.host,
          podcast.notes
        ].join(' ');

        // Fetch related archives using the search terms
        const response = await axios.post('https://caci.blacklitnetwork.org/api/results', {
          searchTerm: searchTerms,
          category: 'collection',
          sortBy: 'collectionTitle',
          orderBy: 'asc',
          page: 1,
          size: 15, // Fetch up to 15 related items
        });

        setRelatedArchives(response.data.data || []);
      } catch (error) {
        console.error('Error fetching related archives:', error);
        setRelatedArchives([]);
      }
      setLoading(false);
    };

    fetchRelatedArchives();
  }, [podcast]);

  const scrollLeft = () => {
    if (containerRef.current) {
      const scrollAmount = containerRef.current.clientWidth / 5;
      containerRef.current.scrollLeft -= scrollAmount;
    }
  };

  const scrollRight = () => {
    if (containerRef.current) {
      const scrollAmount = containerRef.current.clientWidth / 5;
      containerRef.current.scrollLeft += scrollAmount;
    }
  };

  const handleTagClick = (tag) => {
    navigate(`/datavis?category=${encodeURIComponent(tag)}`);
  };

  const handleArchiveClick = (archive) => {
    navigate(`/collection/${archive._id}?from=external`);
  };

  return (
    <div>
      <p className="section-title-related"><i className="fas fa-archive"></i> Related Archives</p>
      <div className="Related-briefs-wrapper">
        <button onClick={scrollLeft} className="nav-button lft-btn-Related"><img src='/leftArrow.svg'></img></button>
        <div ref={containerRef} className='Related-briefs-container'>
          {loading ? (
            <p>Loading...</p>
          ) : (
            relatedArchives.map((archive, index) => (
              <div key={index} className="Related-brief" onClick={() => handleArchiveClick(archive)}>
                <img src={`${process.env.PUBLIC_URL}/placeholderPic.png`} alt={`Cover of ${archive.collectionTitle}`} />
                <div className="Related-brief-info">
                  <i><p className="title">{archive.collectionTitle}</p></i>
                  <div className="tagbuttons">
                    {archive.topics.slice(0, 2).map((topic, topicIndex) => (
                      <TagButton
                        key={topicIndex}
                        onClick={(e) => {
                          e.stopPropagation();  // Stops the click event from bubbling up to the parent elements
                          handleTagClick(topic.label);
                        }}
                      >
                        {topic.label}
                      </TagButton>
                    ))}
                  </div>
                </div>
              </div>
            ))
          )}
        </div>
        <button onClick={scrollRight} className="nav-button rt-btn-Related"><img src='/rightArrow.svg'></img></button>
      </div>
    </div>
  );
};

export default RelatedArchivesForPodcast;
