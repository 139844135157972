/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable array-callback-return */
import React from "react";
import Select from "react-select";
import runOnMount from "./onLoadUtils";
import FileDropZone from "./FileDropzone";
import {
  getAccessKeys,
  getTopics,
  getTiers,
  formSubmission,
} from "./dataFetchingUtils";
import {
  handleInputChange,
  handleSelectChange,
  handleLocationChange,
  handleFormatChange,
  handleContributorTypeChange,
  handleContributorValueChange,
} from "./inputHandlers";
import { showModal, closeModal, validateModal } from "./imageUploadUtils";
import {
  generateContributorField,
  validateForm,
  destroyCurrentContributorField,
} from "./utils/utils";
import "./Form.css";
import withRouter from "./utils/routerUtils";
// import notyfWrapper from "../../utils/notyfUtils";

class Form extends React.Component {
  constructor(props) {
    super(props);
    const { collection } = props;
    this.state = {
      collection,
      accessSelect: [],
      topicSelect: [],
      locationSelect: {
        countries: [],
        states: [],
        cities: [],
      },
      preventSubmit: false,
    };
    this.selectRefs = {
      accessKeys: React.createRef(),
      topics: React.createRef(),
      country: React.createRef(),
      state: React.createRef(),
      city: React.createRef(),
    };
    // eslint-disable-next-line react/no-unused-class-component-methods
    // this.notyf = notyfWrapper.getInstance();
  }

  componentDidMount() {
    const { formName } = this.props;
    // eslint-disable-next-line react/no-unused-class-component-methods
    this.form = document.getElementById(formName);
    getAccessKeys(this);
    getTopics(this);
    runOnMount(this);
    getTiers(this);
  }

  submitForm = (event) => {
    const { preventSubmit } = this.state;
    event.preventDefault();
    if (!preventSubmit) {
      const result = validateForm(this);
      if (result.error) {
        return false;
      }
      this.setState(
        (prevState) => ({ ...prevState, preventSubmit: true }),
        () => {
          formSubmission(this, event, result);
        },
      );
    }
    return undefined;
  };

  renderActionButtons = () => {
    const { formName } = this.props;
    switch (formName) {
      case "insert-collection":
        return (
          <>
            <input
              className="form-button-format"
              type="button"
              value="Submit and Add New"
              onClick={this.submitForm}
            />
            <input
              className="form-button-format"
              type="button"
              id="submit-finish"
              value="Submit and Finish"
              onClick={this.submitForm}
            />
          </>
        );
      case "approve-collection":
        return (
          <input
            className="form-button-format"
            type="button"
            value="Approve Collection"
            onClick={this.submitForm}
          />
        );
      case "update-collection":
        return (
          <input
            className="form-button-format"
            type="button"
            value="Update Collection"
            onClick={this.submitForm}
          />
        );
      default:
        break;
    }
    return "";
  };

  render() {
    const {
      collection,
      accessSelect,
      topicSelect,
      locationSelect: { countries, states, cities },
    } = this.state;
    const {
      collectionTitle,
      collectionDescription,
      collectionUrl,
      collectionPhotos,
      collectionFormat,
      collectionContributor,
      collectionRepository,
      repositoryInstitution,
      collectionRepositoryUrl,
      timePeriod: { from, to },
      topicRecommendation,
      collectionPermission,
      contact: { name, title, email, phone },
      collectionComment,
      // user: { firstName, lastName },
      // submitDate,
      topics,
      accessKeys,
      location: { city, state, country, address, zipcode },
    } = collection;
    const { formName } = this.props;
    return (
      <div className="inner">

        <div className="form-heading">

          {formName === "insert-collection" ? (
            <p>Collection Details</p>
          ) : (
            <p>Collection Details</p>
          )}
        </div>
        <form
          id={formName}
          method="post"
          autoComplete="off"
          encType="multipart/form-data"
        >
          <div className="row">
            <div className="col-12">
              <label htmlFor="collection-title">Collection Title*</label>
              <input
                className="fit"
                type="text"
                id="collection-title"

                value={collectionTitle}
                onChange={handleInputChange.bind(this, this, "collectionTitle")}
              />
            </div>
            <div className="col-12">
              <label htmlFor="collection-url">Collection URL*</label>
              <input
                type="text"
                placeholder="Ex: https://www.google.com"
                id="collection-url"
                value={collectionUrl}
                onChange={handleInputChange.bind(this, this, "collectionUrl")}
              />
            </div>
            <div className="col-13">
              <label>COLLECTION FORMAT(S)*</label>
              <div>
                <input
                type="checkbox"
                className="collection-format"
                id="digital-format"
                checked={collectionFormat.includes("Digital Format")}
                onChange={handleFormatChange.bind(this, this, "Digital Format")}
              />              
              <label htmlFor="digital-format"><b>Digital:</b> Electronic files, scanned documents, digital photos, audio/video files, etc.</label>
              </div>
              <div>

              <input
                type="checkbox"
                className="collection-format"
                id="material-format"
                checked={collectionFormat.includes("Material Format")}
                onChange={handleFormatChange.bind(
                  this,
                  this,
                  "Material Format",
                )}
              />              
              <label htmlFor="material-format"><b>Material:</b> Physical items like paper documents, printed photos, books, objects, etc.</label>
              </div>
              
              <p>Select whether your collection is in digital or material form. If your collection includes both types, select both.</p>

            </div>
            <div className="col-12">
              <label htmlFor="collection-description">
                Collection Description*
              </label>
              <textarea
                id="collection-description"
                rows={6}
                value={collectionDescription}
                onChange={handleInputChange.bind(
                  this,
                  this,
                  "collectionDescription",
                )}
              />
            </div>

            <div className="col-12">
              {formName !== "insert-collection" || collectionPhotos.length ? (
                <label>
                  <span className="c-p">Collection Photos</span>
                  <span className="fa fa-solid fa-images fa-lg c-p" />
                  <div>
                    {collectionPhotos.map((photo) => (
                      <div key={photo.name} className="img-preview-container">
                        <figure style={{ width: "inherit" }}>
                          <img
                            src={photo.url}
                            alt={photo.name}
                            className="image-preview"
                            style={{ width: "inherit", objectFit: "contain" }}
                          />
                          <figcaption>{photo.credit}</figcaption>
                        </figure>
                      </div>
                    ))}
                  </div>
                </label>
              ) : (
                <label>
                  <span className="c-p">
                    Collection Photos (ADD UP TO 4 IMAGES)
                  </span>
                  <div id="insert-image-preview" />
                </label>
              )}
            </div>
<FileDropZone />


            <div className="col-12">
            <p className="form-time-heading">Time period (year)</p>
              <div className="row-time">
                <div className="col-6">
                  <label htmlFor="from">FROM*:</label>
                  <input
                    size={40}
                    type="text"
                    id="from"
                    onChange={handleInputChange.bind(
                      this,
                      this,
                      "timePeriod.from",
                    )}
                    value={from}
                  />
                </div>
                <div className="col-6">

                  <label htmlFor="to">TO*:</label>
                  <input
                    size={40}
                    type="text"
                    id="to"
                    onChange={handleInputChange.bind(
                      this,
                      this,
                      "timePeriod.to",
                    )}
                    value={to}
                  />
                </div>
              </div>
            </div>

            <div className="col-12">
            <p className="form-time-heading">Topics/keywords</p>
              <label htmlFor="collection-topics">
                Select all topics/keywords that apply*
              </label>
              <Select
                value={topics}
                options={topicSelect}
                classNamePrefix="react-select"
                isMulti
                onChange={handleSelectChange.bind(this, this, "topics")}
                ref={this.selectRefs.topics}
              />
            </div>
            <div className="col-12">
              <label htmlFor="topic-recommendation">
                Enter any additional topics/keywords that you want to
                recommend below
              </label>
              <input
                type="text"
                id="topic-recommendation"
                value={topicRecommendation}
                onChange={handleInputChange.bind(
                  this,
                  this,
                  "topicRecommendation",
                )}
              />
            </div>


            <div className="col-12">
              <label htmlFor="collection-contributor" className="form-time-heading">
                People, Organizations Included in Collection
              </label>
              <div className="col-12" id="collection-contributor">
                {collectionContributor.map((contributor) => (
                  <>
                  <div className="role-name">
                    <p>Role</p>
                    <p>Name</p>
                  </div>
                  
                  <div className="contributor-container" key={contributor.key}>
                  
                  <select
                    className="w-30"
                    value={contributor.type.value}
                    onChange={handleContributorTypeChange.bind(
                      this,
                      this,
                      contributor.key,
                    )}
                  >
                    <option value="">Please select</option>
                    <option value="author">Author</option>
                    <option value="public-figure">Public Figure</option>
                    <option value="literary-author">Literary Author</option>
                    <option value="organization">Organization</option>
                    <option value="community-leader">Community Leader</option>
                    {/* <option value="other">Other</option> */}
                  </select>
                  
                  <input
                    className="w-55"
                    type="text"
                    placeholder="Enter Name"
                    value={contributor.value}
                    onChange={handleContributorValueChange.bind(
                      this,
                      this,
                      contributor.key,
                    )}
                  />
                  <span
                    role="button"
                    tabIndex={0}
                    className="icon solid alt c-p"
                    title="Remove"
                    onClick={destroyCurrentContributorField.bind(
                      this,
                      contributor.key,
                    )}
                  >
                  <img src="trashButton.svg" />
                  </span>
                </div>
                  </>
                  
                ))}
              </div>
              <div className="contributor-label-container">
                <span
                  role="button"
                  tabIndex={0}
                  className="icon solid alt c-p"
                  onClick={generateContributorField.bind(this)}
                  title="New"
                >
                + Add more
                </span>
              </div>
            </div>



            <div className="col-12">
              <p className="form-access-detail">Access details</p>
              <label>Access Info (SELECT ALL THAT APPLY)*</label>
              <Select
                value={accessKeys}
                options={accessSelect}
                classNamePrefix="react-select"
                isMulti
                onChange={handleSelectChange.bind(this, this, "accessKeys")}
                ref={this.selectRefs.accessKeys}
              />
            </div>
            <div className="col-12">
              <label htmlFor="collection-permission">
                Please indicate any special instructions for users to access the
                collection
              </label>
              <input
                type="text"
                id="collection-permission"
                value={collectionPermission}
                onChange={handleInputChange.bind(
                  this,
                  this,
                  "collectionPermission",
                )}
              />
            </div>




            <div className="col-12">
            <p className="form-access-detail">Repository details</p>
              <label htmlFor="collection-repository">
                Collection Repository*
              </label>
              <input
                type="text"
                id="collection-repository"
                value={collectionRepository}
                onChange={handleInputChange.bind(
                  this,
                  this,
                  "collectionRepository",
                )}
              />
            </div>
            <div className="col-12">
              <label htmlFor="repository-institution">Institution</label>
              <input
                type="text"
                id="repository-institution"
                value={repositoryInstitution}
                onChange={handleInputChange.bind(
                  this,
                  this,
                  "repositoryInstitution",
                )}
              />
            </div>
            <div className="col-12">
              <label htmlFor="collection-repository-url">Repository URL</label>
              <input
                type="text"
                placeholder="Ex: https://www.google.com"
                id="collection-repository-url"
                value={collectionRepositoryUrl}
                onChange={handleInputChange.bind(
                  this,
                  this,
                  "collectionRepositoryUrl",
                )}
              />
            </div>
            <div className="col-12">
              <label>Repository Country*</label>
              <Select
                value={country}
                options={countries}
                classNamePrefix="react-select"
                onChange={handleLocationChange.bind(this, this, "country")}
                ref={this.selectRefs.country}
              />
            </div>
            <div className="col-12">
              <label>Repository State*</label>
              <Select
                value={state}
                options={states}
                classNamePrefix="react-select"
                onChange={handleLocationChange.bind(this, this, "state")}
                ref={this.selectRefs.state}
              />
            </div>
            <div className="col-12">
              <label>Repository City*</label>
              <Select
                value={city}
                options={cities}
                classNamePrefix="react-select"
                onChange={handleLocationChange.bind(this, this, "city")}
                ref={this.selectRefs.city}
              />
            </div>
            <div className="col-12">
              <label htmlFor="address">Repository Address*</label>
              <input
                type="text"
                id="address"
                value={address}
                onChange={handleInputChange.bind(
                  this,
                  this,
                  "location.address",
                )}
              />
            </div>
            <div className="col-12">
              <label htmlFor="zipcode">Repository Zip code*</label>
              <input
                type="text"
                id="zipcode"
                value={zipcode}
                onChange={handleInputChange.bind(
                  this,
                  this,
                  "location.zipcode",
                )}
              />
            </div>


            <div className="col-12">
            <p className="form-access-detail">Contact info</p>
              <label htmlFor="contact-name">Contact Name*</label>
              <input
                type="text"
                id="contact-name"
                value={name}
                onChange={handleInputChange.bind(this, this, "contact.name")}
              />
            </div>
            <div className="col-12">
              <label htmlFor="contact-title">Contact Title</label>
              <input
                type="text"
                id="contact-title"
                value={title}
                onChange={handleInputChange.bind(this, this, "contact.title")}
              />
            </div>
            <div className="col-12">
              <label htmlFor="contact-email">Contact Email*</label>
              <input
                type="email"
                id="contact-email"
                value={email}
                onChange={handleInputChange.bind(this, this, "contact.email")}
              />
            </div>
            <div className="col-12">
              <label htmlFor="contact-phone">Contact Phone</label>
              <input
                type="tel"
                id="contact-phone"
                value={phone}
                onChange={handleInputChange.bind(this, this, "contact.phone")}
              />
            </div>
            <div className="col-12">
            <p className="form-access-detail">Additional comments</p>
              <label htmlFor="collection-comment">
                Please use the following comment box to tell us anything else
                about the collection. We also welcome and appreciate feedback on
                your experience using the form.
              </label>
              <textarea
                id="collection-comment"
                placeholder="Comments"
                rows="7"
                value={collectionComment}
                onChange={handleInputChange.bind(
                  this,
                  this,
                  "collectionComment",
                )}
              />
            </div>
            <div className="col-12">
              <label style={{ fontSize: "13px" }}>
                <b>
                  All information on this form is public information provided by
                  participating universities. Black Lit Network will request any further
                  permissions for additional information from participants that
                  we would like to share on our website when applicable.
                </b>
              </label>
            </div>
            {/* <div className="col-4 col-12-xsmall">
              <label htmlFor="user-first-name">First Name*</label>
              <input
                type="text"
                id="user-first-name"
                value={firstName}
                onChange={handleInputChange.bind(this, this, "user.firstName")}
              ></input>
            </div>
            <div className="col-4 col-12-xsmall">
              <label htmlFor="user-last-name">Last Name*</label>
              <input
                type="text"
                id="user-last-name"
                value={lastName}
                onChange={handleInputChange.bind(this, this, "user.lastName")}
              ></input>
            </div>
            <div className="col-4 col-12-xsmall">
              <label htmlFor="submit-date">Submit Date*</label>
              <input
                type="date"
                disabled
                id="submit-date"
                value={submitDate}
              ></input>
            </div> */}
            <div className="col-12">
              <p
                style={{
                  fontSize: "13px",
                  fontWeight: "300",
                  textTransform: "uppercase",
                  margin: 0,
                  letterSpacing: "0.25em",
                }}
              >
                <b>
                  Thank you for contributing your collection data to us. We
                  will review your submission and let you know if there is any
                  clarification needed before posting your collection to the
                  site.
                </b>
              </p>
            </div>
            <div className="col-12 txt-align-right">
              {this.renderActionButtons()}
            </div>
          </div>
        </form>
        <div id="myModal" className="modal">
          <div className="modal-content">
            <div className="modal-header">
              <span
                role="button"
                tabIndex={0}
                className="fa fa-solid fa-xmark fa-lg c-p"
                onClick={closeModal.bind(this, this)}
              />
            </div>

          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Form);
