import React, { useState } from 'react';
import './SearchBar.css';

const SearchBar = ({ setSearchTerm, setShouldPerformSearch}) => {
    const [localSearchTerm, setLocalSearchTerm] = useState('');

    const handleSubmit = (event) => {
        event.preventDefault();
        setSearchTerm(localSearchTerm);
        setShouldPerformSearch(true);
        // onSearch(localSearchTerm);
    };

    return (
        <form onSubmit={handleSubmit} className='search-container'>
            <input
                type="text"
                placeholder="Author, Title, Topic, Keyword"
                value={localSearchTerm}
                onChange={(e) => setLocalSearchTerm(e.target.value)}
                className='search-input'
            />
            <button type="submit" className='search-button'>
            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                <path d="M16.1481 14.2239C17.4795 12.4071 18.0758 10.1545 17.8177 7.91695C17.5597 5.67936 16.4663 3.62171 14.7562 2.15567C13.0462 0.689637 10.8457 -0.0766745 8.59497 0.0100511C6.34422 0.0967767 4.2092 1.03014 2.61707 2.62342C1.02493 4.21669 0.0930909 6.35238 0.0079759 8.60319C-0.0771391 10.854 0.690747 13.054 2.15801 14.7629C3.62527 16.4719 5.6837 17.5638 7.92147 17.8203C10.1592 18.0768 12.4113 17.4788 14.2272 16.1461H14.2258C14.2671 16.2011 14.3111 16.2534 14.3606 16.3042L19.6543 21.598C19.9121 21.856 20.2619 22.001 20.6266 22.0011C20.9914 22.0013 21.3413 21.8565 21.5993 21.5987C21.8573 21.3409 22.0023 20.9911 22.0024 20.6264C22.0025 20.2616 21.8578 19.9117 21.5999 19.6537L16.3062 14.36C16.257 14.3102 16.2042 14.2643 16.1481 14.2225V14.2239ZM16.5028 8.93837C16.5028 9.93149 16.3072 10.9149 15.9272 11.8324C15.5471 12.7499 14.9901 13.5836 14.2878 14.2859C13.5856 14.9881 12.7519 15.5452 11.8344 15.9252C10.9168 16.3053 9.93344 16.5009 8.94032 16.5009C7.9472 16.5009 6.9638 16.3053 6.04628 15.9252C5.12875 15.5452 4.29507 14.9881 3.59283 14.2859C2.89058 13.5836 2.33353 12.7499 1.95348 11.8324C1.57343 10.9149 1.37782 9.93149 1.37782 8.93837C1.37782 6.93267 2.17458 5.00912 3.59283 3.59088C5.01107 2.17263 6.93462 1.37587 8.94032 1.37587C10.946 1.37587 12.8696 2.17263 14.2878 3.59088C15.7061 5.00912 16.5028 6.93267 16.5028 8.93837V8.93837Z" fill="#765623"/>
            </svg>
            </button>
        </form>
    );
};

export default SearchBar;
