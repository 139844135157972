import BookSearch from './componets/NovelGen/booksearch';
import {Route, Routes} from 'react-router-dom';
import Footer from './componets/Common/Footer';
import Navbar from './componets/Common/Navbar';
import Homepage from './componets/HomePage/Home';
import DataHome from './componets/DataGallery/DataHome';
import BriefsHome from './componets/Briefs/BriefsHome';
import PodcastHome from './componets/Podcast/Podcasthome';
import ArchivesMain from './componets/Archives/ArchivesMain';
import InsertForm from './componets/Archives/Form/InsertForm';
import AboutUs from './componets/AboutUs/About';

// import "./App.css";


const App = () => {
  return (
      <div className='entireApp'>
      <header className="header">
      <title>BlackLitNetwork</title>
      <link rel="preconnect" href="https://fonts.googleapis.com"></link>
      <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin></link>
      <link href="https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap" rel="stylesheet"></link>
      </header>
        <Navbar />
          <Routes>
            <Route path="/" element={<Homepage />} /> 
            <Route path="/novels" element={<BookSearch />} />
            <Route path="/books/:bookId" element={<BookSearch />} />  
            <Route path="/datavis" element={<DataHome />} />
            <Route path='/datavis/:id' element={<DataHome /> } />
            <Route path='/briefs' element={<BriefsHome/>} />
            <Route path='/briefs/:id' element={<BriefsHome/>} />
            <Route path='/podcasts' element={<PodcastHome/>} />
            <Route path='/podcasts/:id' element={<PodcastHome/>} />
            <Route path='/archives' element={<ArchivesMain />} />
            <Route path='/collection/:id' element={<ArchivesMain />} />
            <Route path='/insert-form' element={<InsertForm />} />
            <Route path='/about' element={<AboutUs />} />
          </Routes>
        <Footer />
      </div>
  );
};

export default App;