import React, { useState, useEffect } from 'react';
import FilterBar from './FilterBar';
import FilteredResults from './FilteredResults';
import { useNavigate, useLocation } from 'react-router-dom';

const ParentComponent = () => {
  const [filters, setFilters] = useState({
    searchTerm: '',
    selectedTopics: [],
    currentPage: 1,
  });
  const [selectedCollection, setSelectedCollection] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();

  const handleFilterChange = ({ searchTerm, selectedTopics }) => {
    // Update the filters state
    setFilters({
      searchTerm: searchTerm || filters.searchTerm,
      selectedTopics: selectedTopics || filters.selectedTopics,
      currentPage: 1,
    });

    // If we're viewing a collection, navigate back to the main archives page
    const urlParams = new URLSearchParams(location.search);
    const fromExternal = urlParams.get('from');

    if (location.pathname.startsWith('/collection/') && !fromExternal) {
      navigate('/archives');
    }
  };

  const handlePageChange = (page) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      currentPage: page,
    }));
  };

  useEffect(() => {
    const currentPath = location.pathname;
    const urlParams = new URLSearchParams(location.search);
    const fromExternal = urlParams.get('from');

    if (currentPath.startsWith('/collection/')) {
      const collectionId = currentPath.split('/collection/')[1];
      setSelectedCollection(collectionId);
    } else if (!fromExternal) {
      setSelectedCollection(null);
    }
  }, [location.pathname, location.search]);

  return (
    <div className='GalleryWrapper'>
      <FilterBar 
        onFilterChange={handleFilterChange} 
        selectedTopics={filters.selectedTopics} // Pass selectedTopics down to FilterBar
      />
      <FilteredResults
        searchTerm={filters.searchTerm}
        selectedTopics={filters.selectedTopics}
        currentPage={filters.currentPage}
        onPageChange={handlePageChange}
        selectedCollection={selectedCollection}
        onCheckboxChange={handleFilterChange}  // Pass this function down to handle tag clicks
      />
    </div>
  );
};

export default ParentComponent;
