import React, { useState, useEffect } from "react";
import PodcastFilterBar from "./PodcastFilter";



const PodcastHome = () => {

    return (
        
            <PodcastFilterBar  />
        
    );
};

export default PodcastHome;
