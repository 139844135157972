import React from 'react';
import { NavLink, Link } from 'react-router-dom';
import './Navbar.css'; 

const Navbar = () => {
  return (
    <div className='finalHeader'>
<div className="social-icons">
    <a href="https://twitter.com" className="social-icon x">
        <img src={`${process.env.PUBLIC_URL}/Vector.png`} alt="Twitter" />
    </a>
    <a href="https://facebook.com" className="social-icon face">
        <img src={`${process.env.PUBLIC_URL}/facebook.png`} alt="Facebook" />
    </a>
    <a href="https://instagram.com" className="social-icon insta">
        <img src={`${process.env.PUBLIC_URL}/instagram.png`} alt="Instagram" />
    </a>
</div>


      <nav className="navbar">
        <div className="nav-links">
          <Link to="/" className='logo-link'>
            <img src="/logo2.png" alt="Logo" className="logo" />
          </Link>
          <NavLink to="/novels" className={({ isActive }) => isActive ? "active" : ""}>Novels</NavLink>
          <NavLink to="/archives" className={({ isActive }) => isActive ? "active" : ""}>Archives</NavLink>
          <NavLink to="/podcasts" className={({ isActive }) => isActive ? "active" : ""}>Podcasts</NavLink>
          <NavLink to="/datavis" className={({ isActive }) => isActive ? "active" : ""}>Data Vis</NavLink>
          <NavLink to="/briefs" className={({ isActive }) => isActive ? "active" : ""}>Briefs</NavLink>
          <NavLink to="/about" className={({ isActive }) => isActive ? "active" : ""}>About HBW</NavLink>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
