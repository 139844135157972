import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import './FilteredResults.css';
import Tooltip from './Tooltip';  // Import the Tooltip component
import iconData from './iconData.json';  // Import your cleaned JSON file

const TagButton = ({ category, children, isSelected, onClick }) => (
  <button onClick={onClick} style={{
      display: 'flex',
      padding: '6px 12px',
      alignItems: 'center',
      gap: '4px',
      borderRadius: '8px',
      border: '0.25px solid var(--Gray-Black, #00000090)',
      background: isSelected ? 'white' : 'transparent',
      cursor: 'pointer',
      color: '#00000090'
  }}>
      {children}
  </button>
);


// Map the icon tags to the corresponding SVG file names and tooltip descriptions
const iconMap = {
  'People': {
    src: `${process.env.PUBLIC_URL}/archivePeople.svg`,
    tooltip: 'People-focused archives',
    displayText: 'People'
  },
  'papers and publications': {
    src: `${process.env.PUBLIC_URL}/archivePaper.svg`,
    tooltip: 'Papers and Publications archives',
    displayText: 'Papers and Publications'
  },
  'cultural projects and institutions': {
    src: `${process.env.PUBLIC_URL}/archiveInstitute.svg`,
    tooltip: 'Cultural projects and institutions archives',
    displayText: 'Cultural Projects and Institutions'
  },
  'audio/visual': {
    src: `${process.env.PUBLIC_URL}/archiveAudioVisual.svg`,
    tooltip: 'Audio and visual archives',
    displayText: 'Audio/Visual'
  }
};

// Helper function to get the corresponding icon and tooltip for a collectionTitle
const getIconForTitle = (title) => {
  const iconEntry = iconData.find(item => item.collectionTitle === title);

  if (iconEntry && iconMap[iconEntry.iconTag]) {
    return iconMap[iconEntry.iconTag];
  }
  return null;  // Fallback if no match is found
};

const FilteredResults = ({ searchTerm, selectedTopics, currentPage, onPageChange, onCheckboxChange }) => {
  const [results, setResults] = useState([]);
  const [totalResults, setTotalResults] = useState(0);
  const resultsPerPage = 8; // 8 results per page
  const navigate = useNavigate(); 
  const location = useLocation();

  const navigateToInsertForm = () => {
    navigate('/insert-form');
  };

  useEffect(() => {
    const fetchResults = async () => {
      try {
        const topicsArray = Array.isArray(selectedTopics) ? selectedTopics : [];

        const collectionId = location.pathname.split('/collection/')[1];
        if (collectionId) {
          // If a collection is selected, fetch its details
          const response = await axios.post('https://caci.blacklitnetwork.org/api/collection', { _id: collectionId });
          setResults([response.data.data]);
          setTotalResults(1);
        } else {
          // Fetch results based on search and filters
          const response = await axios.post('https://caci.blacklitnetwork.org/api/results', {
            searchTerm: searchTerm,
            category: topicsArray.length > 0 ? 'topic' : 'collection',
            value: topicsArray.join(','),
            sortBy: 'collectionTitle',
            orderBy: 'asc',
            page: currentPage,
            size: resultsPerPage,
          });

          setResults(response.data.data || []);
          setTotalResults(response.data.total || 0);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        setResults([]);
      }
    };

    fetchResults();
  }, [searchTerm, selectedTopics, currentPage, location.pathname]);

  const handleTagClick = (topicValue) => {
    onCheckboxChange({
      searchTerm,
      selectedTopics: selectedTopics.includes(topicValue) 
        ? selectedTopics.filter(t => t !== topicValue) 
        : [...selectedTopics, topicValue]
    });
  };

  // Helper function to handle empty or undefined values
  const displayValue = (value, fallback = 'N/A') => value || fallback;

  // Calculate the number of pages
  const totalPages = Math.ceil(totalResults / resultsPerPage);

  // Generate page numbers for pagination
  const generatePageNumbers = () => {
    const pages = [];
    const maxPagesToShow = 8;
    const firstPage = Math.max(1, currentPage - 3);
    const lastPage = Math.min(totalPages, currentPage + 4);

    if (firstPage > 1) {
      pages.push('<');
      pages.push(1);
      if (firstPage > 2) {
        pages.push('...');
      }
    }

    for (let i = firstPage; i <= lastPage; i++) {
      pages.push(i);
    }

    if (lastPage < totalPages) {
      if (lastPage < totalPages - 1) {
        pages.push('...');
      }
      pages.push(totalPages);
      pages.push('>');
    }

    return pages;
  };

  const handleCollectionClick = (id, e) => {
    e.preventDefault(); 
    navigate(`/collection/${id}`);
  };

  return (
    <div className='Gallery-Right'>
      {location.pathname.startsWith('/collection/') && results.length > 0 ? (
        <div className="collection-detail">
          <div className='collection-detail-holder'>
            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
              {getIconForTitle(results[0].collectionTitle)?.src && (
                <img src={getIconForTitle(results[0].collectionTitle).src} alt="collection icon" style={{ width: '24px', height: '24px' }} />
              )}
              <p className='caci-detail-icon-name'>{getIconForTitle(results[0].collectionTitle)?.displayText || 'N/A'}</p>
            </div>

            <p className='pod-title'>{displayValue(results[0].collectionTitle)}</p>
            <div className="caci-tags-container">
              {results[0].topics && results[0].topics.length > 0
                ? results[0].topics.map((topic, index) => (
                    <TagButton 
                      key={index} 
                      category={topic} 
                      isSelected={selectedTopics.includes(topic.value)} 
                      onClick={() => handleTagClick(topic.value)}
                    >
                      {topic.label}
                    </TagButton>
                  ))
                : 'N/A'}
            </div>
            <div className='colect-l-r-wrapper'>
              <div className='collection-detail-holder-left'>
                
                <div className='collection-desc'> 
                  <p><strong>Description:</strong> </p>
                  <p>{displayValue(results[0].collectionDescription)}</p>
                </div>

                <div className='collection-desc'> 
                  <p><strong>Time Period:</strong> </p>
                  <p>{results[0].timePeriod ? `${results[0].timePeriod.from} - ${results[0].timePeriod.to}` : 'N/A'}</p>
                </div>

                <div className='collection-desc'> 
                  <p><strong>Collection Contributor</strong></p>
                  <p>{results[0].collectionContributor && results[0].collectionContributor.length > 0 ? results[0].collectionContributor.map(contr => contr.value).join(', ') : 'N/A'}</p>
                </div>

                <div className='collection-desc'> 
                  <p><strong>Collection Format</strong></p>
                  <p>{displayValue(results[0].collectionFormat.join(', '))}</p>
                </div>
                
              </div>

              <div className='collection-detail-holder-right'>
                <div className='collection-desc'>
                  <p><strong>Collection URL</strong></p>
                  <a 
      href={results[0].collectionUrl} 
      style={{ 
        wordBreak: 'break-all', 
        display: 'inline-block', 
        maxWidth: '100%' 
      }}>{displayValue(results[0].collectionUrl)}</a>
                </div>

                <div className='collection-desc'>
                  <p><strong>Contact</strong></p>
                  <p>{displayValue(results[0].contact?.name)}</p>
                  <p>{displayValue(results[0].contact?.title)}</p>
                  <a>{displayValue(results[0].contact?.email)}</a>
                  <p>{displayValue(results[0].contact?.phone)}</p>
                </div>

                <div className='collection-desc'>
                  <p><strong>Institution</strong></p>
                  <p>{displayValue(results[0].repositoryInstitution)}</p>
                  <a>{results[0].location ? `${displayValue(results[0].location.address)}, ${displayValue(results[0].location.city.label)}, ${displayValue(results[0].location.state.label)}, ${displayValue(results[0].location.country.label)}` : 'N/A'}</a>
                </div>

                <div className='collection-desc'>
                  <p><strong>Repository URL</strong></p>
                  <a 
      href={results[0].collectionRepositoryUrl} 
      style={{ 
        wordBreak: 'break-all', 
        display: 'inline-block', 
        maxWidth: '100%' 
      }}>{displayValue(results[0].collectionRepositoryUrl)}</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className='caci-intro'>
            <p style= {{display: "flex", gap: "1rem"}} ><img src="/info.svg" /> Introduction</p>
            <p>The Current Archives and Collections Index (CACI) collates basic information on Black-themed archive collections at universities, libraries, and other institutions as a starting point for scholars, educators, and enthusiasts interested in archival research. With a central focus on African American literary figures, CACI includes hundreds of collections documenting Black life, history, and culture through such materials as photographs, publications, reports, letters, notes, and other primary sources.</p>
          </div>
          {results.length > 0 ? (
            <>
              <div className='caci-heading'>
                <div className='caci-innerheading'>
                  <svg xmlns="http://www.w3.org/2000/svg" width="22" height="23" viewBox="0 0 22 23" fill="none">
                    <path d="M3.44031 5.31445C3.25797 5.31445 3.0831 5.24202 2.95417 5.11309C2.82524 4.98416 2.75281 4.80929 2.75281 4.62695C2.75281 4.44462 2.82524 4.26975 2.95417 4.14082C3.0831 4.01189 3.25797 3.93945 3.44031 3.93945H18.5653C18.7476 3.93945 18.9225 4.01189 19.0514 4.14082C19.1804 4.26975 19.2528 4.44462 19.2528 4.62695C19.2528 4.80929 19.1804 4.98416 19.0514 5.11309C18.9225 5.24202 18.7476 5.31445 18.5653 5.31445H3.44031ZM6.19031 2.56445C6.00797 2.56445 5.8331 2.49202 5.70417 2.36309C5.57524 2.23416 5.50281 2.05929 5.50281 1.87695C5.50281 1.69462 5.57524 1.51975 5.70417 1.39082C5.8331 1.26189 6.00797 1.18945 6.19031 1.18945H15.8153C15.9976 1.18945 16.1725 1.26189 16.3014 1.39082C16.4304 1.51975 16.5028 1.69462 16.5028 1.87695C16.5028 2.05929 16.4304 2.23416 16.3014 2.36309C16.1725 2.49202 15.9976 2.56445 15.8153 2.56445H6.19031ZM0.00280762 18.377C0.00280762 18.924 0.220106 19.4486 0.6069 19.8354C0.993694 20.2222 1.5183 20.4395 2.06531 20.4395H19.9403C20.4873 20.4395 21.0119 20.2222 21.3987 19.8354C21.7855 19.4486 22.0028 18.924 22.0028 18.377V8.75195C22.0028 8.20494 21.7855 7.68034 21.3987 7.29355C21.0119 6.90675 20.4873 6.68945 19.9403 6.68945H2.06531C1.5183 6.68945 0.993694 6.90675 0.6069 7.29355C0.220106 7.68034 0.00280762 8.20494 0.00280762 8.75195V18.377ZM2.06531 19.0645C1.88297 19.0645 1.7081 18.992 1.57917 18.8631C1.45024 18.7342 1.37781 18.5593 1.37781 18.377V8.75195C1.37781 8.56962 1.45024 8.39475 1.57917 8.26582C1.7081 8.13689 1.88297 8.06445 2.06531 8.06445H19.9403C20.1226 8.06445 20.2975 8.13689 20.4264 8.26582C20.5554 8.39475 20.6278 8.56962 20.6278 8.75195V18.377C20.6278 18.5593 20.5554 18.7342 20.4264 18.8631C20.2975 18.992 20.1226 19.0645 19.9403 19.0645H2.06531Z" fill="#1A1A1A"/>
                  </svg>

                  <p>Archival Collection</p>
                </div>

                <button onClick={navigateToInsertForm} className='caci-submit-form'>Submit to Collection</button>
              </div>
              <table className="custom-results-table">
                <colgroup>
                  <col style={{ width: "7%" }} />  {/* Type */}
                  <col style={{ width: "20%" }} />  {/* Title */}
                  <col style={{ width: "13%" }} />  {/* Time */}
                  <col style={{ width: "22%" }} />  {/* Institution */}
                  <col style={{ width: "38%" }} />  {/* Topics/Keywords */}
                </colgroup>
                <thead>
                  <tr>
                    <th className='custom-header'>Type</th>
                    <th className="custom-header">Title</th>
                    <th className="custom-header">Time</th>
                    <th className="custom-header">Institution</th>
                    <th className="custom-header">Topics/Keywords</th>
                  </tr>
                </thead>
                <tbody>
                  {results.map((result) => (
                    <tr key={result._id} className="custom-row">
                      {/* Get the icon for the collectionTitle */}
                      <td className='custom-cell'>
                        {getIconForTitle(result.collectionTitle) ? (
                          <Tooltip text={getIconForTitle(result.collectionTitle).tooltip}>
                            <img src={getIconForTitle(result.collectionTitle).src} alt={getIconForTitle(result.collectionTitle).iconTag} className="icon" />
                          </Tooltip>
                        ) : 'N/A'}
                      </td>
                      <td className="custom-cell">
                        <a
                          href="#"
                          className="custom-link text-ellipsis--4"
                          onClick={(e) => handleCollectionClick(result._id, e)}
                        >
                          {result.collectionTitle}
                        </a>
                      </td>
                      <td className="custom-cell text-ellipsis--4">
                        {result.timePeriod ? `${result.timePeriod.from} - ${result.timePeriod.to}` : 'N/A'}
                      </td>
                      <td className="custom-cell text-ellipsis--4">
                        {result.repositoryInstitution || 'N/A'}
                      </td>
                      <td className="custom-cell text-ellipsis--4">
                        {result.topics && result.topics.length > 0
                          ? result.topics.map((topic) => topic.label).join(', ')
                          : 'N/A'}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>

              <div className="pagination-container">
                <span>Total {totalResults} items</span>
                <div className="pagination">
                  {generatePageNumbers().map((page, index) => (
                    <button
                      key={index}
                      className={`pagination-button ${page === currentPage ? 'active' : ''}`}
                      onClick={() => {
                        if (page === '<' && currentPage > 1) {
                          onPageChange(currentPage - 1);
                        } else if (page === '>' && currentPage < totalPages) {
                          onPageChange(currentPage + 1);
                        } else if (typeof page === 'number') {
                          onPageChange(page);
                        }
                      }}
                      disabled={page === '...' || (page === '<' && currentPage === 1) || (page === '>' && currentPage === totalPages)}
                    >
                      {page}
                    </button>
                  ))}
                </div>
              </div>
            </>
          ) : (
            <p>No collections available</p>
          )}
        </>
      )}
    </div>
  );
};

export default FilteredResults;
