import React from "react";

class FileDropZone extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      files: [],
      invalidFiles: [], // To store any invalid files
    };
    this.fileInputRef = React.createRef();
  }

  handleDragOver = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  handleDrop = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const { files } = event.dataTransfer;
    this.handleFiles(files);
  };

  handleFiles = (files) => {
    const validImageTypes = ["image/jpeg", "image/png", "image/gif"];
    const fileList = Array.from(files);

    const validFiles = fileList.filter((file) =>
      validImageTypes.includes(file.type)
    );

    const invalidFiles = fileList.filter(
      (file) => !validImageTypes.includes(file.type)
    );

    // Update input's files property with valid files
    this.fileInputRef.current.files = this.createFileList(validFiles);

    this.setState((prevState) => ({
      files: [...prevState.files, ...validFiles],
      invalidFiles: [...prevState.invalidFiles, ...invalidFiles],
    }));
  };

  createFileList = (files) => {
    const dataTransfer = new DataTransfer();
    files.forEach((file) => dataTransfer.items.add(file));
    return dataTransfer.files;
  };

  handleClick = () => {
    this.fileInputRef.current.click();
  };

  handleFileChange = (event) => {
    const { files } = event.target;
    this.handleFiles(files);
  };

  generateImagePreviews = () => {
    return this.state.files.map((file, index) => {
      const objectUrl = URL.createObjectURL(file);
      return (
        <div key={index} className="img-preview-container">
          <figure style={{ width: "inherit" }}>
            <img
              src={objectUrl}
              alt={file.name}
              className="image-preview"
              style={{ width: "50px", objectFit: "contain" }}
            />
            <figcaption>{file.name}</figcaption>
          </figure>
        </div>
      );
    });
  };

  render() {
    return (
      <div className="modal-body">
        <div className="col-12">
          <label htmlFor="collection-photos" style={{ marginLeft: "0.5em" }}>
            <span className="c-p">Select Images</span>
            <span className="fa fa-solid fa-images fa-lg c-p" />
          </label>
          <div className="col-12">
            <input
              id="collection-photos"
              type="file"
              accept="image/*"
              style={{ display: "none" }}
              multiple
              ref={this.fileInputRef} // Reference to the hidden file input
              onChange={this.handleFileChange}
            />
          </div>
          <div
            id="row-of-product-photos"
            className="col-12 row-of-id-photos"
            onDragOver={this.handleDragOver}
            onDrop={this.handleDrop}
            onClick={this.handleClick}
            style={{
              border: "2px dashed #cccccc",
              padding: "20px",
              textAlign: "center",
              cursor: "pointer",
            }}
          >
            <p>Drag & Drop image files here or click to select files</p>
          </div>
        </div>

        {/* Display selected image previews */}
        <div className="col-12 row-of-id-photos" id="row-of-product-photos">
          {this.generateImagePreviews()}
        </div>

        <div className="col-12">
          {this.state.invalidFiles.length > 0 && (
            <div>
              <h3>Invalid Files:</h3>
              <ul>
                {this.state.invalidFiles.map((file, index) => (
                  <li key={index}>{file.name} (Invalid file type)</li>
                ))}
              </ul>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default FileDropZone;
