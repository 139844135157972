import React from 'react';
import { useNavigate } from 'react-router-dom';
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination} from 'swiper/modules';
import "swiper/css";
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import "./Slider.css"


const Slider = () => {
  const navigate = useNavigate();
  const handleButtonClick = () => {
    // Navigate to the specific page, you can pass the necessary info here
    navigate('/datavis/');
};

    return (
    <>
      <Swiper navigation={true} pagination={{clickable: true}} modules={[Navigation, Pagination]} className="mySwiper">
        <SwiperSlide id='Slider1'> 
        <p>Visioning the invisibles</p>
        <button onClick={handleButtonClick}>10 Hidden Black Authors We Should Know</button>
        </SwiperSlide>
        <SwiperSlide>Slide 2</SwiperSlide>
        <SwiperSlide>Slide 3</SwiperSlide>
        <SwiperSlide>Slide 4</SwiperSlide>
        <SwiperSlide>Slide 5</SwiperSlide>
      </Swiper>
    </>
    );
};

export default Slider;