import React , {useState, useEffect} from 'react';

import Slider from './Slider';
import DataVis from './DataVis';
import Info from './Info'
import Podcast from './Podcast';
import FeatNovel from './FeatNovel';
import FeatArch from './FeatArch';
import B2Digi from './B2Digi';
import FeatNotes from './FeatNotes';

import './Home.css';



const Home = () => {

    const [isVisible, setIsVisible] = useState(false);

    const toggleVisibility = () => {
        if (window.pageYOffset > 500) { // Adjust the value based on your needs
          setIsVisible(true);
        } else {
          setIsVisible(false);
        }
      };
    
      useEffect(() => {
        window.addEventListener('scroll', toggleVisibility);
    
        return () => {
          window.removeEventListener('scroll', toggleVisibility);
        };
      }, []);

    return (
        <>
        {isVisible && (
            <button
            className="back-to-top-button"
            onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
            aria-label="Back to top">
                <span className="back-to-top-text">Back to Top</span>
            </button>
        )}
            <div className='HomeContent'>

            <Slider />
                <div className='HomeBlocks'>

                    <div className='HomeBlockLeft'>
                    <DataVis />
                    <FeatNovel />
                    <FeatNotes />
                    </div>

                    <div className='HomeBlockMid'>
                    <Info />
                    <FeatArch />
                    </div>
                    
                    <div className='HomeBlockRight'>
                    <Podcast />
                  
                    </div>
                    
                </div>
            </div>
            
        </>
        
    );
};

export default Home;