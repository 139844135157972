/* eslint-disable react/no-unused-state */
import React from "react";

import Form from "./Form";

class InsertForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      state: "pending",
      collectionTitle: "",
      collectionUrl: "",
      collectionFormat: [],
      accessKeys: [],
      collectionPermission: "",
      collectionDescription: "",
      collectionContributor: [
        {
          type: { label: "Select", value: "" },
          value: "",
          key: `contributor_${Math.random().toString(16).slice(2)}`,
        },
        {
          type: { label: "Select", value: "" },
          value: "",
          key: `contributor_${Math.random().toString(16).slice(2)}`,
        },
        {
          type: { label: "Select", value: "" },
          value: "",
          key: `contributor_${Math.random().toString(16).slice(2)}`,
        },
      ],
      timePeriod: { from: "1700", to: "1800" },
      topics: [],
      topicRecommendation: "",
      collectionPhotos: [],
      collectionRepository: "",
      repositoryInstitution: "",
      collectionRepositoryUrl: "",
      location: {
        city: "",
        state: "",
        country: "",
        address: "",
        zipcode: "",
        regions: {
          "tier-1": "",
          "tier-2": "",
          "tier-3": "",
        },
      },
      contact: {
        name: "",
        title: "",
        email: "",
        phone: "",
      },
      collectionComment: "",
      acknowledgement: false,
      imageAcknowledgement: false,
      // user: { firstName: "", lastName: "" },
      // submitDate: new Date().toLocaleDateString("en-CA"),
    };
  }

  render() {
    const { logoutCallback } = this.props;
    return (
      <>
        <div id="caci-form-wrapper">
          
          <div id="caci-form-main">
            
              <div className="inner">
                <div className="major">
                  <p>Collection submission Form</p>
                </div>

              </div>
            
            <section id="four">                
            <div className="form-content">
                  <p>
                    Please fill out the form below as completely as possible.
                    The more accurate the information, the greater the
                    likelihood that we will be able to connect users to your
                    collection.
                  </p>
                  <p>* Required field</p>
                </div>
              <Form formName="insert-collection" collection={this.state} />
              
            </section>
          </div>
        </div>
      </>
    );
  }
}

export default InsertForm;
