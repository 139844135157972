import React, { useState, useEffect } from "react";
import Papa from 'papaparse';
import './About.css';
import { styled } from '@mui/system';
import { Tabs } from '@mui/base/Tabs';
import { TabsList as BaseTabsList } from '@mui/base/TabsList';
import { TabPanel as BaseTabPanel } from '@mui/base/TabPanel';
import { buttonClasses } from '@mui/base/Button';
import { Tab as BaseTab, tabClasses } from '@mui/base/Tab';


const Tab = styled(BaseTab)`
color: rgb(177, 131, 102);
cursor: pointer;
color: var(--Theme-Primary, #765623);
/* Body/Regular */
font-family: Avenir;
font-size: 1rem;
font-style: normal;
font-weight: 350;
line-height: 130%; /* 1.3rem */
letter-spacing: 0.05rem;
background-color: transparent;
padding-top: 10px;
padding-bottom: 8px;
padding-left: 16px;
padding-right: 16px;

margin: 6px;
margin-left:0px;
margin-right:0px;

border: none;
border-radius: 4px;
display: flex;
justify-content: center;

&:hover {
  color: rgb(218, 173, 145);
}

&:focus {
  color: #fff;
}

&.${tabClasses.selected} {
  background-color: #fff;
    color: var(--Body-Text-Body-Color, #1A1A1A);
    /* Body/Bold */
    font-family: Avenir;
    font-size: 1rem;
    font-style: normal;
    font-weight: 900;
    line-height: 130%; /* 1.3rem */
    letter-spacing: 0.05rem;
    }

&.${buttonClasses.disabled} {
  opacity: 0.5;
  cursor: not-allowed;
}
`;

const TabPanel = styled(BaseTabPanel)(
({ theme }) => `

color: var(--Body-Text-Body-Color, #1A1A1A);

/* Body/Regular */
font-family: Avenir;
font-size: 1rem;
font-style: normal;
font-weight: 350;
line-height: 130%; /* 1.3rem */
letter-spacing: 0.05rem;
padding-left: 50px;
padding-right: 50px;
padding-top: 30px;
padding-bottom: 20px;
min-height: 100px;
background-color: rgba(255, 255, 255, 0.88);
margin-top: -25px;
`,
);

const TabsList = styled(BaseTabsList)(
({ theme }) => `

border-radius: 12px;
margin-bottom: 16px;
display: flex;
align-items: center;

gap: 16px;
`,
);


const AboutUs = () => {
    const [isVisible, setIsVisible] = useState(false);
    const [peopleData, setPeopleData] = useState([]);

// Parse CSV on mount
useEffect(() => {
    Papa.parse('/aboutusicons/people.csv', {
        download: true,
        header: true,
        complete: (results) => {
            const cleanedData = results.data.map(row => {
                // Replace '�' with a blank or another character
                for (const key in row) {
                  if (typeof row[key] === 'string') {
                    row[key] = row[key].replace(/�/g, ' ');
                  }
                }
                return row;
              });
              setPeopleData(cleanedData);
        },
    });
}, []);

// Chunk the peopleData into groups of three
const chunkSize = 3;
const chunkedData = [];
for (let i = 0; i < peopleData.length; i += chunkSize) {
    chunkedData.push(peopleData.slice(i, i + chunkSize));
}


    const toggleVisibility = () => {
        if (window.pageYOffset > 500) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    };



    useEffect(() => {
        window.addEventListener('scroll', toggleVisibility);

        return () => {
            window.removeEventListener('scroll', toggleVisibility);
        };
    }, []);

    return (
        <div className="AboutUs">
            {isVisible && (
                <button
                    className="back-to-top-button"
                    onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
                    aria-label="Back to top">
                    <span className="back-to-top-text">Back to Top</span>
                </button>
            )}

            <div className="filter-bar">
                <div className="filter-bar-header">
                    <img src="/aboutusicons/circle-i.svg" alt="Info Icon" />
                    About Us
                </div>
                <div className="AboutUs-Filter-bar">
                    <div className="AboutUsFilters">
                        <a href="#black-lit-network">What is Black Lit Network?</a>
                        <a href="#how-to-use">How to use Black Lit Network?</a>
                        <a href="#our-people">Our People</a>
                        <a href="#partnered-institutions">Partnered Institutions</a>
                        <a href="#funding-agencies">Funding Agencies</a>
                    </div>
                </div>
            </div>

            <div className="aboutusrender">
                <div className="aboutsub aboutsub-black-lit-network" id="black-lit-network">
                    <img src="/aboutusicons/dots.svg" alt="Dots Icon" />
                    <p>What is Black Lit Network?</p>

                </div>
                <div className="abt-Info-Content">
                    <p>
                    Portals making ideas about African American novels and collections available through a podcast, visualizations, briefs, and interactive search engines.
                    </p>

                    <div className="Info-Img-div-about">
                        <div className="image-containerabout">
                            <img src="BlackLit1.png" alt="Description of Image 1"></img>
                            <p>Studying Black Writing</p>
                        </div>
                        <div className="image-containerabout">
                            <img src="BlackLit2.png" alt="Description of Image 2"></img>
                            <p>7,000 Black novels and memoirs</p>
                        </div>
                        <div className="image-containerabout">
                            <img src="BlackLit3.png" alt="Description of Image 3"></img>
                            <p>Support research and learning</p>
                        </div>
                    </div>
                    
                </div>

                <div className="aboutsub aboutsub-how-to-use" id="how-to-use">
                    <img src="/aboutusicons/circle-question-mark.svg" alt="Question Mark Icon" />
                    <p>How to use Black Lit Network?</p>
                </div>

                
                    <div className="aboutushow">
                        <Tabs defaultValue={0}>
                        <TabsList>
                            <Tab value={0}>Educator</Tab>
                            <Tab value={1}>Student</Tab>
                            <Tab value={2}>Researcher</Tab>
                            <Tab value={3}>Librarian</Tab>
                            <Tab value={4}>Book Lover</Tab>
                        </TabsList>
                        <TabPanel value={0}>BBN is designed to make information and ideas about African American novels widely available. The portals for the Network address several basic yet enduring questions: What’s a good African American novel to read? What’s the story behind the publication of some famous novels by black writers? What’s the relationship of one novel to dozens of other novels? What key terms assist us in understanding the works of black writers?</TabPanel>
                        <TabPanel value={1}>
                        <div className="InfoPanel2">
                            <img src="/Frame116.png"></img>
                            <img src="/Frame117.png"></img>
                            <img src="/Frame118.png"></img>
                            <p>You can find Black novels, writings and podcasts about history and scholarly discourse on Black literature for curriculum development.</p>
                        </div>

                        </TabPanel>
                        <TabPanel value={2}>N/A</TabPanel>
                        <TabPanel value={3}>N/A</TabPanel>
                        <TabPanel value={4}>N/A</TabPanel>
                        </Tabs>
                    </div>
                
                    <div className="abt-people-wrapper">
                <div className="aboutsub aboutsub-our-people" id="our-people">
                    <img src="/aboutusicons/people.svg" alt="People Icon" />
                    <p>Our People</p>
                </div>

                {/* Map over chunked data to maintain three people per container */}
                {chunkedData.map((group, groupIndex) => (
                    <div className="abt-peopleContainer" key={groupIndex}>
                        {group.map((person, index) => {
                            console.log(person["First Name"]?.trim())
                            const firstName = person["First Name"]?.trim() || "";
                            const lastName = person["Last Name"]?.trim() || "";
                            const fullName = `${firstName} ${lastName}`.trim();
                            const institution = person["Institution"] || "";
                            const projectRole = person["Project Role (i.e. Co-PI, Contributor, Data Ranger, etc.)"] || "";
                            const bio = person["Bio - 215 characters, including spaces"] || "";

                            // Construct image path assuming naming convention "FirstName LastName.jpg"
                            const imageName = `${firstName} ${lastName}.jpg`;
                            const imageSrc = `/aboutusicons/contributorphotos/${imageName}`;

                            return (
                                <div className="abt-peoples" key={index}>
                                    <div className="abt-people-photos">
                                        <img src={imageSrc} alt={fullName} />
                                        <p className="abt-description">{projectRole}</p>
                                    </div>
                                    <div className="abt-people-desc">
                                        <p className="abt-people-name">{fullName}</p>
                                        <p className="abt-people-title">{institution}</p>
                                        <p className="abt-people-short">{bio}</p>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                ))}
            </div>
                

                <div className="aboutsub aboutsub-partnered-institutions" id="partnered-institutions">
                    <img src="/aboutusicons/institutions.svg" alt="Institutions Icon" />
                    <p>Partnered Institutions</p>
                </div>

                <div className="about-partner-image-container">

                    <div className="abt-partners">
                        <img
                            src="/aboutusicons/KU2x.svg"
                            alt="University of Kansas (KU)"
                            title="University of Kansas (KU)"
                            onClick={() => window.open('https://www.ku.edu', '_blank')}
                            style={{ cursor: 'pointer' }}
                        />
                        <p style={{marginBottom: "0"}}>University of Kansas</p>
                        <p style={{margin: "0"}}><i>Leading University</i></p>
                    </div>

                    <div className="abt-partners">
                        <img
                            src="/aboutusicons/SIU2x.svg"
                            alt="Southern Illinois University Edwardsville (SIUE)"
                            title="Southern Illinois University Edwardsville (SIUE)"
                            onClick={() => window.open('https://www.siue.edu', '_blank')}
                            style={{ cursor: 'pointer' }}
                        />
                        <p>Southern Illinois University Edwardsville</p>
                    </div>

                    <div className="abt-partners">
                        <img
                            src="/aboutusicons/UTA2x.svg"
                            alt="University of Texas at Arlington (UTA)"
                            title="University of Texas at Arlington (UTA)"
                            onClick={() => window.open('https://www.uta.edu', '_blank')}
                            style={{ cursor: 'pointer' }}
                        />
                        <p>University of Texas at Arlington</p>
                    </div>

                    

                </div>

                <div className="aboutsub aboutsub-funding-agencies" id="funding-agencies">
                    <img src="/aboutusicons/funding.svg" alt="Funding Icon" />
                    <p>Funding Agencies</p>
                </div>

                <div className="abt-funding-container">
                    <div className="abt-funders">
                        <img
                            src="/aboutusicons/Mellon2x.svg"
                            alt="Mellon Foundation"
                            title="Mellon Foundation"
                            onClick={() => window.open('https://mellon.org', '_blank')}
                            style={{ cursor: 'pointer' }}
                        />
                        <p>Andrew W. Mellon Foundation</p>
                    </div>
                    <div className="abt-funders">
                        <img
                            src="/aboutusicons/NEH2x.svg"
                            alt="National Endowment for the Humanities"
                            title="National Endowment for the Humanities"
                            onClick={() => window.open('https://www.neh.gov', '_blank')}
                            style={{ cursor: 'pointer' }}
                        />
                        <p>National Endowment for the Humanities</p>
                    </div>
                </div>
                <p style={{marginBottom: "2rem"}}>The Black Literature Network is funded by a generous grant from the Public Knowledge division of the Mellon Foundation.  

The Archives section of this site is also supported by a Digital Humanities Advancement Grant from the National Endowment for the Humanities.   </p>
            </div>
        </div>
    );
};

export default AboutUs;
