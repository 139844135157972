 import React, { useState, useRef, useEffect } from 'react';

import "./player.css"

const AudioPlayer = ({ audiofile }) => {
    console.log('Audio file object:', audiofile); // Log the entire audiofile object
    console.log('Audio file path:', audiofile.path); // Log the audio file path

    const audioPath = audiofile.path.replace(/^\./, '');
    const [isPlaying, setIsPlaying] = useState(false);
    const [isMuted, setIsMuted] = useState(false);
    const [currentTime, setCurrentTime] = useState(0);
    const [duration, setDuration] = useState(0);
    const [isDragging, setIsDragging] = useState(false);
    const audioRef = useRef(null);

    const togglePlay = () => {
        const audio = audioRef.current;
        if (audio.paused) {
            audio.play().catch(error => {
                console.error('Error playing audio:', error);
            });
            setIsPlaying(true);
        } else {
            audio.pause();
            setIsPlaying(false);
        }
    };

    const toggleMute = () => {
        const audio = audioRef.current;
        audio.muted = !audio.muted;
        setIsMuted(audio.muted);
    };

    const skipTime = (time) => {
        const audio = audioRef.current;
        audio.currentTime += time;
    };

    const handleTimeUpdate = () => {
        if (!isDragging) {
            setCurrentTime(audioRef.current.currentTime);
        }
    };

    const handleLoadedData = () => {
        setDuration(audioRef.current.duration);
    };

    const handleSliderChange = (e) => {
        setCurrentTime(parseFloat(e.target.value));
    };

    const handleSliderMouseDown = () => {
        setIsDragging(true);
    };

    const handleSliderMouseUp = () => {
        setIsDragging(false);
        audioRef.current.currentTime = currentTime;
        if (isPlaying) {
            audioRef.current.play().catch(error => {
                console.error('Error playing audio:', error);
            });
        }
    };

    useEffect(() => {
        const audio = audioRef.current;
        audio.addEventListener('timeupdate', handleTimeUpdate);
        audio.addEventListener('loadeddata', handleLoadedData);

        return () => {
            audio.removeEventListener('timeupdate', handleTimeUpdate);
            audio.removeEventListener('loadeddata', handleLoadedData);
        };
    }, []);

    const formatTime = (time) => {
        const minutes = Math.floor(time / 60);
        const seconds = Math.floor(time % 60);
        return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    };

    return (
        <div className="audio-player">
            <audio ref={audioRef} src={audioPath} preload="metadata">
                Your browser does not support the audio element.
            </audio>
            <button onClick={() => skipTime(-10)} className="skip-btns">
                <img src="/replay_10.svg" alt="Replay 10 seconds" />
            </button>
            <button onClick={togglePlay} className="play-pause-btn">
                {isPlaying ? <img src="/pause-circle-fill.svg" alt="Pause" /> : <img src="/play-circle-fill.svg" alt="Play" /> }
            </button>
            <button onClick={() => skipTime(10)} className="skip-btns">
                <img src="/forward_10.svg" alt="Forward 10 seconds" />
            </button>
            <input
                type="range"
                value={currentTime}
                step="1"
                min="0"
                max={duration || 0}
                onChange={handleSliderChange}
                onMouseDown={handleSliderMouseDown}
                onMouseUp={handleSliderMouseUp}
                onTouchStart={handleSliderMouseDown}
                onTouchEnd={handleSliderMouseUp}
                className="progress-bar"
            />
            <div className="time">
                {formatTime(currentTime)} / {formatTime(duration)}
            </div>
            <button onClick={toggleMute} className="mute-btn">
                {isMuted ? <img src="/mute-button.svg" alt="Muted" /> : <img src="/mute-button.svg" alt="Unmuted" /> }
            </button>
        </div>
    );
    
};

export default AudioPlayer;
