import React, { useState, useEffect } from 'react';
import axios from 'axios';
import subheadingsData from './output.json';
import './FilterBar.css';

const CollapsibleSection = ({ subheading, topics, onCheckboxChange, selectedTopics }) => {
  const [isOpen, setIsOpen] = useState(true);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="collapsible-section">
      <h3 onClick={toggleOpen} className="filter-heading">
        {subheading} <span className="arrow">{isOpen ? '▲' : '▼'}</span>
      </h3>
      {isOpen && (
        <div className="scrollable-section">
          {topics.map((topic, index) => (
            <div key={index} className="Gallery-select">
              <label>
                <input
                  type="checkbox"
                  value={topic.value}
                  checked={selectedTopics.includes(topic.value)}
                  onChange={() =>
                    onCheckboxChange({
                      searchTerm: '',
                      selectedTopics: selectedTopics.includes(topic.value)
                        ? selectedTopics.filter((t) => t !== topic.value)
                        : [...selectedTopics, topic.value],
                    })
                  }
                />
                {topic.label}
              </label>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

const FilterBar = ({ onFilterChange, selectedTopics }) => {
  const [filterData, setFilterData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const topicsResponse = await axios.post('https://caci.blacklitnetwork.org/api/topics');
        const topics = topicsResponse.data.data || [];

        const structuredData = Object.keys(subheadingsData).map((subheading) => {
          const subheadingTopics = subheadingsData[subheading]
            .map((topicLabel) => topics.find((topic) => topic.label === topicLabel))
            .filter(Boolean);
          
          return { subheading, topics: subheadingTopics };
        });

        setFilterData(structuredData);
      } catch (error) {
        console.error('Error fetching topics:', error);
      }
    };

    fetchData();
  }, []);

  const handleCheckboxChange = (updatedFilters) => {
    onFilterChange(updatedFilters); // Ensure selectedTopics gets updated and syncs with the parent
  };

  const handleSearchChange = (e) => {
    const term = e.target.value;
    setSearchTerm(term);
    onFilterChange({ searchTerm: term, selectedTopics });
  };

  return (
    <div className="filter-bar">
      <div className="filter-bar-header">                  
      <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
        <path d="M3.44031 4.81445C3.25797 4.81445 3.0831 4.74202 2.95417 4.61309C2.82524 4.48416 2.75281 4.30929 2.75281 4.12695C2.75281 3.94462 2.82524 3.76975 2.95417 3.64082C3.0831 3.51189 3.25797 3.43945 3.44031 3.43945H18.5653C18.7476 3.43945 18.9225 3.51189 19.0514 3.64082C19.1804 3.76975 19.2528 3.94462 19.2528 4.12695C19.2528 4.30929 19.1804 4.48416 19.0514 4.61309C18.9225 4.74202 18.7476 4.81445 18.5653 4.81445H3.44031ZM6.19031 2.06445C6.00797 2.06445 5.8331 1.99202 5.70417 1.86309C5.57524 1.73416 5.50281 1.55929 5.50281 1.37695C5.50281 1.19462 5.57524 1.01975 5.70417 0.890817C5.8331 0.761886 6.00797 0.689453 6.19031 0.689453H15.8153C15.9976 0.689453 16.1725 0.761886 16.3014 0.890817C16.4304 1.01975 16.5028 1.19462 16.5028 1.37695C16.5028 1.55929 16.4304 1.73416 16.3014 1.86309C16.1725 1.99202 15.9976 2.06445 15.8153 2.06445H6.19031ZM0.00280762 17.877C0.00280762 18.424 0.220106 18.9486 0.6069 19.3354C0.993694 19.7222 1.5183 19.9395 2.06531 19.9395H19.9403C20.4873 19.9395 21.0119 19.7222 21.3987 19.3354C21.7855 18.9486 22.0028 18.424 22.0028 17.877V8.25195C22.0028 7.70494 21.7855 7.18034 21.3987 6.79355C21.0119 6.40675 20.4873 6.18945 19.9403 6.18945H2.06531C1.5183 6.18945 0.993694 6.40675 0.6069 6.79355C0.220106 7.18034 0.00280762 7.70494 0.00280762 8.25195V17.877ZM2.06531 18.5645C1.88297 18.5645 1.7081 18.492 1.57917 18.3631C1.45024 18.2342 1.37781 18.0593 1.37781 17.877V8.25195C1.37781 8.06962 1.45024 7.89475 1.57917 7.76582C1.7081 7.63689 1.88297 7.56445 2.06531 7.56445H19.9403C20.1226 7.56445 20.2975 7.63689 20.4264 7.76582C20.5554 7.89475 20.6278 8.06962 20.6278 8.25195V17.877C20.6278 18.0593 20.5554 18.2342 20.4264 18.3631C20.2975 18.492 20.1226 18.5645 19.9403 18.5645H2.06531Z" fill="white"/>
      </svg>
      Archives</div>
      <div className="filter-bar-content">
        <div className="search-container">
          <input
            type="text"
            placeholder="Author, title, topic, keyword"
            value={searchTerm}
            onChange={handleSearchChange}
            className="search-input"
          />
          <button type="submit" class="search-button"><svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none"><path d="M16.1481 14.2239C17.4795 12.4071 18.0758 10.1545 17.8177 7.91695C17.5597 5.67936 16.4663 3.62171 14.7562 2.15567C13.0462 0.689637 10.8457 -0.0766745 8.59497 0.0100511C6.34422 0.0967767 4.2092 1.03014 2.61707 2.62342C1.02493 4.21669 0.0930909 6.35238 0.0079759 8.60319C-0.0771391 10.854 0.690747 13.054 2.15801 14.7629C3.62527 16.4719 5.6837 17.5638 7.92147 17.8203C10.1592 18.0768 12.4113 17.4788 14.2272 16.1461H14.2258C14.2671 16.2011 14.3111 16.2534 14.3606 16.3042L19.6543 21.598C19.9121 21.856 20.2619 22.001 20.6266 22.0011C20.9914 22.0013 21.3413 21.8565 21.5993 21.5987C21.8573 21.3409 22.0023 20.9911 22.0024 20.6264C22.0025 20.2616 21.8578 19.9117 21.5999 19.6537L16.3062 14.36C16.257 14.3102 16.2042 14.2643 16.1481 14.2225V14.2239ZM16.5028 8.93837C16.5028 9.93149 16.3072 10.9149 15.9272 11.8324C15.5471 12.7499 14.9901 13.5836 14.2878 14.2859C13.5856 14.9881 12.7519 15.5452 11.8344 15.9252C10.9168 16.3053 9.93344 16.5009 8.94032 16.5009C7.9472 16.5009 6.9638 16.3053 6.04628 15.9252C5.12875 15.5452 4.29507 14.9881 3.59283 14.2859C2.89058 13.5836 2.33353 12.7499 1.95348 11.8324C1.57343 10.9149 1.37782 9.93149 1.37782 8.93837C1.37782 6.93267 2.17458 5.00912 3.59283 3.59088C5.01107 2.17263 6.93462 1.37587 8.94032 1.37587C10.946 1.37587 12.8696 2.17263 14.2878 3.59088C15.7061 5.00912 16.5028 6.93267 16.5028 8.93837V8.93837Z" fill="#765623"></path></svg></button>
        </div>

        {filterData.length > 0 ? (
          <div className="filtjsx">
            {filterData.map((section, index) => (
              <CollapsibleSection
                key={index}
                subheading={section.subheading}
                topics={section.topics}
                onCheckboxChange={handleCheckboxChange}
                selectedTopics={selectedTopics} // Pass the current selectedTopics down to keep in sync
              />
            ))}
          </div>
        ) : (
          <p>No topics available</p>
        )}
      </div>
    </div>
  );
};

export default FilterBar;
