import React from "react";
import { useNavigate } from 'react-router-dom';
import './FeatNotes.css';


const FeatNotes = () => {

    const TagButton = ({ children }) => (
        <button style={{
          display: 'flex',
          padding: '6px 12px',
          alignItems: 'center',
          gap: '4px',
          borderRadius: '8px',
          border: '0.25px solid var(--Gray-Black, #000)',
          backgroundColor: 'transparent',
          /* Small Shadow */
          boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.08)',
          marginBottom: '8px',
        }}>
          {children}
        </button>
      );

      const navigate = useNavigate();

      const handleButtonClick = () => {
          navigate('/briefs/2a5ad744-d551-46e4-8945-fc54fbf6c71a');
      };

    return(
        <div className="FeatNotes-Container" id="FeatNotes">
            <div className="FeatNotes-header">
            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
  <path d="M19.9403 4.1261C20.1226 4.1261 20.2975 4.19853 20.4264 4.32746C20.5554 4.45639 20.6278 4.63126 20.6278 4.8136V17.1886C20.6278 17.3709 20.5554 17.5458 20.4264 17.6747C20.2975 17.8037 20.1226 17.8761 19.9403 17.8761H2.06531C1.88297 17.8761 1.7081 17.8037 1.57917 17.6747C1.45024 17.5458 1.37781 17.3709 1.37781 17.1886V4.8136C1.37781 4.63126 1.45024 4.45639 1.57917 4.32746C1.7081 4.19853 1.88297 4.1261 2.06531 4.1261H19.9403ZM2.06531 2.7511C1.5183 2.7511 0.993694 2.9684 0.6069 3.35519C0.220106 3.74198 0.00280762 4.26659 0.00280762 4.8136V17.1886C0.00280762 17.7356 0.220106 18.2602 0.6069 18.647C0.993694 19.0338 1.5183 19.2511 2.06531 19.2511H19.9403C20.4873 19.2511 21.0119 19.0338 21.3987 18.647C21.7855 18.2602 22.0028 17.7356 22.0028 17.1886V4.8136C22.0028 4.26659 21.7855 3.74198 21.3987 3.35519C21.0119 2.9684 20.4873 2.7511 19.9403 2.7511H2.06531Z" fill="white"/>
  <path d="M4.12781 7.5636C4.12781 7.38126 4.20024 7.20639 4.32917 7.07746C4.4581 6.94853 4.63297 6.8761 4.81531 6.8761H17.1903C17.3726 6.8761 17.5475 6.94853 17.6764 7.07746C17.8054 7.20639 17.8778 7.38126 17.8778 7.5636C17.8778 7.74593 17.8054 7.9208 17.6764 8.04973C17.5475 8.17867 17.3726 8.2511 17.1903 8.2511H4.81531C4.63297 8.2511 4.4581 8.17867 4.32917 8.04973C4.20024 7.9208 4.12781 7.74593 4.12781 7.5636ZM4.12781 11.0011C4.12781 10.8188 4.20024 10.6439 4.32917 10.515C4.4581 10.386 4.63297 10.3136 4.81531 10.3136H17.1903C17.3726 10.3136 17.5475 10.386 17.6764 10.515C17.8054 10.6439 17.8778 10.8188 17.8778 11.0011C17.8778 11.1834 17.8054 11.3583 17.6764 11.4872C17.5475 11.6162 17.3726 11.6886 17.1903 11.6886H4.81531C4.63297 11.6886 4.4581 11.6162 4.32917 11.4872C4.20024 11.3583 4.12781 11.1834 4.12781 11.0011ZM4.12781 14.4386C4.12781 14.2563 4.20024 14.0814 4.32917 13.9525C4.4581 13.8235 4.63297 13.7511 4.81531 13.7511H13.0653C13.2476 13.7511 13.4225 13.8235 13.5514 13.9525C13.6804 14.0814 13.7528 14.2563 13.7528 14.4386C13.7528 14.6209 13.6804 14.7958 13.5514 14.9247C13.4225 15.0537 13.2476 15.1261 13.0653 15.1261H4.81531C4.63297 15.1261 4.4581 15.0537 4.32917 14.9247C4.20024 14.7958 4.12781 14.6209 4.12781 14.4386Z" fill="white"/>
</svg>
                <p>Featured Brief</p>
            </div>
            <div className="FeatNotes-content" onClick={handleButtonClick}>
                <p><strong>Barbara Christian, Black women novelists, and Berkeley</strong></p>
                <p style={{textAlign: "left"}}>By Elizabeth Cali</p>
                <div className="FeatNotes-tagbuttons">
                    <TagButton>Book & Literary History</TagButton>
                    <TagButton>Freedom</TagButton>
                    <TagButton>Collective Behavior</TagButton>
                    <TagButton>Rebellion</TagButton>
                    <TagButton>Virginia</TagButton>
                    <TagButton>Bontemps, Arna</TagButton>
                </div>
            </div>

        </div>
    );
};

export default FeatNotes;