import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const FilterBar = ({ onFilterChange, filters, onLuckyClick, categories, levels, periods, genders, locations, awards, resetFilters }) => {
  const [showLevels, setShowLevels] = useState(true);
  const [showGenres, setShowGenres] = useState(true);
  const [showPeriods, setShowPeriods] = useState(true);
  const [showGenders, setShowGenders] = useState(true);
  const [showLocations, setShowLocations] = useState(true);
  const [showAwards, setShowAwards] = useState(true);

  const navigate = useNavigate();

  const toggleLevels = () => setShowLevels(!showLevels);
  const toggleGenres = () => setShowGenres(!showGenres);
  const togglePeriods = () => setShowPeriods(!showPeriods);
  const toggleGenders = () => setShowGenders(!showGenders);
  const toggleLocations = () => setShowLocations(!showLocations);
  const toggleAwards = () => setShowAwards(!showAwards);

  const handleFilterChange = (e) => {
    const { name, checked } = e.target;
    onFilterChange(name, checked);

    navigate('/novels'); 
  };

  return (
    <div className='filtjsx'>
      <div>
        <h3 onClick={toggleGenders} style={{ cursor: 'pointer' }} className='filter-heading'>
          Author Characteristics <span className='arrow'>{showGenders ? '▲' : '▼'}</span>
        </h3>
        {showGenders && (
          <div className="scrollable-section">
            {genders.map(gender => (
              <div key={gender}>
                <input
                  type="checkbox"
                  id={gender}
                  name={gender}
                  checked={filters[gender] || false}
                  onChange={handleFilterChange}
                />
                <label htmlFor={gender}>{gender}</label>
              </div>
            ))}
          </div>
        )}
      </div>

      <div>
        <h3 onClick={toggleLevels} style={{ cursor: 'pointer' }} className='filter-heading'>
          Level <span className='arrow'>{showLevels ? '▲' : '▼'}</span>
        </h3>
        {showLevels && (
          <div className="scrollable-section">
            {levels.map(level => (
              <div key={level}>
                <input
                  type="checkbox"
                  id={level}
                  name={level}
                  checked={filters[level] || false}
                  onChange={handleFilterChange}
                />
                <label htmlFor={level}>{level}</label>
              </div>
            ))}
          </div>
        )}
      </div>

      <div>
        <h3 onClick={toggleGenres} style={{ cursor: 'pointer' }} className='filter-heading'>
          Genres <span className='arrow'>{showGenres ? '▲' : '▼'}</span>
        </h3>
        {showGenres && (
          <div className="scrollable-section">
            {categories.map(category => (
              <div key={category}>
                <input
                  type="checkbox"
                  id={category}
                  name={category}
                  checked={filters[category] || false}
                  onChange={handleFilterChange}
                />
                <label htmlFor={category}>{category}</label>
              </div>
            ))}
          </div>
        )}
      </div>

      <div>
        <h3 onClick={togglePeriods} style={{ cursor: 'pointer' }} className='filter-heading'>
          Period of Publication <span className='arrow'>{showPeriods ? '▲' : '▼'}</span>
        </h3>
        {showPeriods && (
          <div className="scrollable-section">
            {periods.map(period => (
              <div key={period}>
                <input
                  type="checkbox"
                  id={`period-${period}`}
                  name={period}
                  checked={filters[period] || false}
                  onChange={handleFilterChange}
                />
                <label htmlFor={`period-${period}`}>{period}</label>
              </div>
            ))}
          </div>
        )}
      </div>

      <div>
        <h3 onClick={toggleLocations} style={{ cursor: 'pointer' }} className='filter-heading'>
          Location/Region <span className='arrow'>{showLocations ? '▲' : '▼'}</span>
        </h3>
        {showLocations && (
          <div className="scrollable-section">
            {locations.map(location => (
              <div key={location}>
                <input
                  type="checkbox"
                  id={location}
                  name={location}
                  checked={filters[location] || false}
                  onChange={handleFilterChange}
                />
                <label htmlFor={location}>{location}</label>
              </div>
            ))}
          </div>
        )}
      </div>

      <div>
        <h3 onClick={toggleAwards} style={{ cursor: 'pointer' }} className='filter-heading'>
          Major Awards <span className='arrow'>{showAwards ? '▲' : '▼'}</span>
        </h3>
        {showAwards && (
          <div className="scrollable-section">
            {awards.map(award => (
              <div key={award}>
                <input
                  type="checkbox"
                  id={award}
                  name={award}
                  checked={filters[award] || false}
                  onChange={handleFilterChange}
                />
                <label htmlFor={award}>{award}</label>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default FilterBar;
