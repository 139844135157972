import React from "react";

import './FeatArch.css';


const FeatArch = () => {
    return(
        <div className="FeatArch-Container" id="FeatArch">
            <div className="FeatArch-header">
                <img src='/FeatArchIcon.png'></img>
                <p>Featured Archival Collection</p>
            </div>
            <div className="FeatArch-content">
                <h4 id="FeatArchTitle">The Original KC (KS) Athletics in the Late Forties</h4>
                <img id="FeatArchImg" src="originalKCAthlete.png"></img>
                <p id="FeatArchP">Late 1940’s photograph of the Kansas City (Kansas) Athletics at Heathwood Park in Kansas City, Kansas. Pictured are Readie White (1st row, far right), Moses Byers (2nd row, 5th from left), Jesse Byers (2nd row, 6th from left), and Thomas A. Benton (2nd row, 7th from left).
<br/>Owned and contributed by Vern Byers and Gail Anthony-Byers.
<br/>Location: Heathwood Park, Kansas City, Kansas</p>
            </div>
        </div>
    );
};

export default FeatArch;