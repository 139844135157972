// Icons.js
import React from 'react';

export const BookIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="23" height="22" viewBox="0 0 23 22" fill="none">
    <path d="M1.87781 3.88959C3.09468 3.38084 4.83956 2.83221 6.53631 2.66171C8.36506 2.47746 9.91606 2.74834 10.8153 3.69571V17.0965C9.52968 16.3677 7.90031 16.2673 6.39743 16.4186C4.77493 16.5836 3.13868 17.0525 1.87781 17.5337V3.88959ZM12.1903 3.69571C13.0896 2.74834 14.6406 2.47746 16.4693 2.66171C18.1661 2.83221 19.9109 3.38084 21.1278 3.88959V17.5337C19.8656 17.0525 18.2307 16.5822 16.6082 16.42C15.1039 16.2673 13.4759 16.3663 12.1903 17.0965V3.69571ZM11.5028 2.45271C10.1484 1.28809 8.18493 1.11484 6.39743 1.29359C4.31568 1.50396 2.21468 2.21759 0.905683 2.81296C0.785575 2.86759 0.683724 2.95562 0.612286 3.06656C0.540849 3.17749 0.502844 3.30664 0.502808 3.43859V18.5636C0.502839 18.6786 0.531734 18.7918 0.586844 18.8928C0.641954 18.9937 0.721517 19.0793 0.818247 19.1415C0.914977 19.2038 1.02578 19.2408 1.14051 19.2491C1.25524 19.2574 1.37022 19.2368 1.47493 19.1892C2.68768 18.6392 4.64156 17.9778 6.53493 17.7867C8.47231 17.5915 10.0962 17.9063 10.9666 18.9926C11.031 19.0729 11.1126 19.1377 11.2054 19.1822C11.2982 19.2267 11.3999 19.2499 11.5028 19.2499C11.6058 19.2499 11.7074 19.2267 11.8002 19.1822C11.893 19.1377 11.9746 19.0729 12.0391 18.9926C12.9094 17.9063 14.5333 17.5915 16.4693 17.7867C18.3641 17.9778 20.3193 18.6392 21.5307 19.1892C21.6354 19.2368 21.7504 19.2574 21.8651 19.2491C21.9798 19.2408 22.0906 19.2038 22.1874 19.1415C22.2841 19.0793 22.3637 18.9937 22.4188 18.8928C22.4739 18.7918 22.5028 18.6786 22.5028 18.5636V3.43859C22.5028 3.30664 22.4648 3.17749 22.3933 3.06656C22.3219 2.95562 22.22 2.86759 22.0999 2.81296C20.7909 2.21759 18.6899 1.50396 16.6082 1.29359C14.8207 1.11346 12.8572 1.28809 11.5028 2.45271Z" fill="white"/>
  </svg>
);

export const FeaturedIcon = () => (
<svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
    <path d="M10.7828 5.63872C10.8029 5.59749 10.8342 5.56273 10.8731 5.53842C10.912 5.51411 10.9569 5.50122 11.0028 5.50122C11.0487 5.50122 11.0936 5.51411 11.1325 5.53842C11.1714 5.56273 11.2027 5.59749 11.2228 5.63872L12.0946 7.4056C12.112 7.44134 12.1379 7.47229 12.17 7.49577C12.2021 7.51925 12.2395 7.53455 12.2788 7.54035L14.2313 7.8236C14.4307 7.85247 14.5118 8.0986 14.3661 8.24022L12.9553 9.6166C12.9268 9.64438 12.9056 9.67869 12.8934 9.71654C12.8811 9.7544 12.8783 9.79466 12.8852 9.83385L13.2179 11.7781C13.2254 11.823 13.2202 11.8691 13.2029 11.9112C13.1857 11.9533 13.157 11.9897 13.1201 12.0165C13.0833 12.0432 13.0397 12.0591 12.9943 12.0624C12.9489 12.0657 12.9035 12.0563 12.8632 12.0352L11.1169 11.1167C11.0819 11.0984 11.043 11.0888 11.0035 11.0888C10.964 11.0888 10.9251 11.0984 10.8901 11.1167L9.14381 12.0352C9.10351 12.0559 9.05826 12.065 9.01309 12.0615C8.96793 12.058 8.92462 12.0421 8.88799 12.0154C8.85136 11.9888 8.82284 11.9525 8.80562 11.9106C8.78839 11.8687 8.78314 11.8228 8.79043 11.7781L9.12318 9.83385C9.1302 9.79477 9.12761 9.75458 9.11563 9.71673C9.10366 9.67888 9.08265 9.64452 9.05443 9.6166L7.63818 8.24022C7.60574 8.20837 7.58281 8.16811 7.57197 8.12396C7.56112 8.07981 7.56279 8.03351 7.57678 7.99026C7.59077 7.947 7.61653 7.9085 7.65118 7.87906C7.68582 7.84962 7.72798 7.83042 7.77293 7.8236L9.72543 7.54035C9.76478 7.53455 9.80212 7.51925 9.83423 7.49577C9.86633 7.47229 9.89223 7.44134 9.90968 7.4056L10.7828 5.63872Z" fill="#1A1A1A"/>
    <path d="M2.75281 2.75122C2.75281 2.02188 3.04254 1.3224 3.55826 0.806677C4.07399 0.290952 4.77346 0.0012207 5.50281 0.0012207H16.5028C17.2322 0.0012207 17.9316 0.290952 18.4474 0.806677C18.9631 1.3224 19.2528 2.02188 19.2528 2.75122V21.3137C19.2527 21.4381 19.2189 21.5601 19.155 21.6668C19.0911 21.7734 18.9994 21.8608 18.8898 21.9195C18.7802 21.9782 18.6567 22.006 18.5325 22.0001C18.4082 21.9941 18.288 21.9546 18.1844 21.8857L11.0028 18.0151L3.82118 21.8857C3.71765 21.9546 3.59737 21.9941 3.47316 22.0001C3.34894 22.006 3.22544 21.9782 3.11581 21.9195C3.00617 21.8608 2.91451 21.7734 2.85059 21.6668C2.78667 21.5601 2.75287 21.4381 2.75281 21.3137V2.75122ZM5.50281 1.37622C5.13813 1.37622 4.7884 1.52109 4.53054 1.77895C4.27267 2.03681 4.12781 2.38655 4.12781 2.75122V20.0295L10.6219 16.6167C10.7348 16.5416 10.8673 16.5016 11.0028 16.5016C11.1383 16.5016 11.2709 16.5416 11.3837 16.6167L17.8778 20.0295V2.75122C17.8778 2.38655 17.7329 2.03681 17.4751 1.77895C17.2172 1.52109 16.8675 1.37622 16.5028 1.37622H5.50281Z" fill="#1A1A1A"/>
  </svg>
);
